import {Component as VueComponent} from 'vue/types/options'
import Utils from '@/utils'

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type Modify<T, R> = Omit<T, keyof R> & R;

export type LangValue =
  | string
  | { [x: string]: JSONValue }

export type Lang = Record<string, LangValue>

export type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>;

export type JSONObject = Record<string, JSONValue>

export class Popup {
  public readonly id: string

  constructor(
    public popup: VueComponent,
    public popupProps: Record<string, any> = {},
    public popupTitle: string | null = null,
    public hideTopbar: boolean = false,
    public fullScreen: boolean = false,
  ) {
    this.id = Utils.hash(popup.name + JSON.stringify(popupProps)).toString()
  }
}

export class Dialog {
  public readonly id: string

  public readonly defaultDialogConfirmedEvent: string = 'dialogConfirm'
  public dialogConfirmedEvent: string = 'dialogConfirm'

  constructor(
    public dialog: VueComponent,
    public dialogProps: Record<string, any> = {},
    public dialogTitle: string | null = null,
    public hideTopbar: boolean = false,
    public emitConfirm: string | null = null,
  ) {
    this.id = Utils.hash(dialog.name + JSON.stringify(dialogProps)).toString()
    if (typeof emitConfirm === 'string') {
      this.dialogConfirmedEvent = emitConfirm
    }
  }

  public isDefaultConfirm() {
    return this.dialogConfirmedEvent === this.defaultDialogConfirmedEvent
  }
}

export enum BookingMode {
  RT = 'RT',
  FF = 'FF',
  OWFF = 'OWFF',
  CRT = 'CRT',
  STR = 'STR'
}

export enum VehicleType {
  KICKSCOOTER = 'KICKSCOOTER',
  BIKE = 'BIKE',
  SCOOTER = 'SCOOTER',
  AMI = 'AMI',
  MICRO = 'MICRO',
  CAR = 'CAR',
  VAN = 'VAN',
  TRUCK = 'TRUCK',
  MINIBUS = 'MINIBUS',
  PLATFORM = 'PLATFORM',
  HORSE = 'HORSE',
  BOAT = 'BOAT'
}

export interface GenericResponse {
  messages: string[]
  result: string
  result_code: string
}

export interface QuickRegistrationRequest {
  client_type_id: string
  phone: string
  name: string
  surname: string
  email: string
  birthdate: string
  gender: string
  accepted_tocs_id: string[]
}

export interface LicenseType {
  attachment_views: string[]
  description: string
  has_emission: boolean
  has_expiry: boolean
  has_issuer: boolean
  has_country: boolean
  has_number: boolean
  id: string
  name: string
}

export interface Client {
  id: string
  status: 'ACTIVE' | 'ON HOLD' | 'SUSPENDED' | 'CANCELED'
  name: string
  surname: string
  email: string
  logo_url: null
  phone: string
  mobile: string
  organization_name: string
  address: Address
  billing_address: Address
  fiscal_code: string
  vat_number: string
  client_type: ClientType
  main_driver: Driver
  drivers: Driver[]
  extra: Extra
}

export interface ClientType {
  id: string
  code: string
  type: string
}

export interface Driver {
  id: string
  status: 'ON HOLD' | 'PENDING REVIEW' | 'APPROVED' | 'REFUSED' | 'ACTIVE' | 'SUSPENDED'
  profile_picture: string
  username: string
  title: string
  name: string
  surname: string
  birthdate: string | Date
  gender: string
  email: string
  phone: string
  mobile: string
  address: Address
  main_driver: boolean
  birthplace: string | null
}

export class Address {
  country: string = ''
  region: string = ''
  city: string = ''
  postal_code: string = ''
  street: string = ''
  street_number: string = ''
}

export interface LoginRequest {
  username: string | null
  password: string | null
}

export class LoginResponse {
  public token!: string
  public driver!: Driver
  public pin!: null | string
}

export interface SearchRequest {
  start?: string
  end?: string
  booking_mode?: Array<keyof typeof BookingMode>
  latitude?: number
  longitude?: number
  vehicle_type_id?: string[]
  vehicle_category_id?: string[]
  min_range?: number
  parking_lot?: string[]
}

export interface ResetPasswordRequest {
  identification: string | null
}

export interface VehicleSlot {
  id: string
  name: string
  lot: Lot
  reservation_type: BookingMode
  distance: number | null
  position: Position
  vehicle: Vehicle
  base_rate: Rate
}

export interface Lot {
  id: string
  name: string
  description?: string | null
  picture: string
  position: Position
}

export type ParkingLot = Modify<Lot, {
  position: Pos
  parking_slots_availability: {
    total: number,
    available: number,
    available_vehicles: number
  }
}>

export interface Position {
  latitude: number
  longitude: number
  accuracy?: number
}

export interface Pos {
  lat: number
  lng: number
  acc?: number
}

export class Vehicle {
  id = ''
  plate = ''
  model = ''
  brand = ''
  brand_logo = ''
  door = ''
  color = ''
  fuel_type: 'PETROL' | 'DIESEL' | 'HYBRID' | 'GAS' | 'ELECTRIC' | 'HYDROGEN' | 'NONE' | 'OTHER' = 'NONE'
  fuel_level: number = 0
  range?: number
  category = new class implements VehicleCategory {
    damage_picture = ''
    description = ''
    id = ''
    name = ''
    picture = ''
    type = '' as VehicleType
  }
  picture = ''
  notes: any[] = []
  attributes: Attribute[] = []
  instructions: instruction[] = []
}

export interface instruction {
  id: string
  steps: instructionStep[]
  title: string
  subtitle: string
}

export interface instructionStep {
  type: string
  value: string
  href?: string
}

export interface VehicleCategory {
  damage_picture: string
  id: string
  name: string
  description: string
  type: VehicleType
  picture: string
}

export interface BookTimeSelectRequest {
  start: string
  end: string
}

export interface BookRequest extends BookTimeSelectRequest {
  plate: string
  memo: string | null
  packet_id: string | null
}

export interface CorporateBookRequest extends BookTimeSelectRequest {
  parking_lot_id: string,
  vehicle_category_id: string,
  memo: null | string,
  position?: Position
}

export enum ReservationStatus {
  PLANNED = 'PLANNED',
  SENT = 'SENT',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR', // unused?
  CHARGED = 'CHARGED', // unused?
  SUSPENDED = 'SUSPENDED', // unused?
}

export interface ReservationExtra {
  actions: string[]
  hardware_actions?: [] | { [key: string]: string }

  [k: string]: any
}

export interface ReservationResponse {
  id: string
  number: number
  type: string
  start: string
  end: string
  status: ReservationStatus
  driver: Driver
  vehicle_slot: VehicleSlot
  trips: Trip[]
  cost: number | null
  memo: string | null
  distance: number
  created: string
  updated: string
  start_timestamp: number
  end_timestamp: number
  created_timestamp: number
  updated_timestamp: number
  current_vehicle_position: Position | undefined
  extra: ReservationExtra
  flags: ReservationFlag[]
  reportings: ReportResponse[]
  is_validated?: boolean
  free_until?: string | null
  free_until_timestamp?: number | null
}

export interface BookingRequest {
  id: string
  start: Date
  end: Date
  notes: string
  flags: ReserVationRequestFlags
  status: 'NEW' | 'WAITING' | 'ACCEPTED' | 'REJECTED'
  pick_up: DropOff
  drop_off: DropOff
  reservation: ReservationResponse
  created_at: Date | string
  updated_at: Date | string
  deleted_at: Date | string
  start_timestamp: number
  end_timestamp: number
}

export interface ReserVationRequestFlags {
  pick_up_id: number
  drop_off_id: number
  offroad: boolean
  passengers: string
}

export interface DropOff {
  id: string
  name: string
  picture: string
  description: string
  position: Position
}

export interface Trip extends Position {
  distance: number
  end: string
  end_timestamp: number
  fuel_level: number
  id: string
  speed: number
  start: string
  start_timestamp: number

}

export interface ReservationFlag {
  reservation_number: number
  flag: string
  value: string
}

export interface AvailabilityRequest {
  start: string
  end: string
  vehicle_id: string
}

export interface AvailabilityResponse {
  start: string
  end: string
  available: boolean
  start_timestamp: number
  end_timestamp: number
  selected: boolean
}

export interface EstimateRequest {
  distance: number,
  start: string,
  end: string,
  plate: string
}

export interface EstimateRequestAll {
  distance: number,
  start: string,
  end: string,
  booking_mode: string
}

export interface Estimate {
  time_cost: number
  distance_cost: number
}

export interface EstimateCategories {
  rate: VehicleCategory
  costs: Estimate
}

export interface EstimateCurrent {
  duration: number,
  distance: number
  cost: number | null,
}

// Wallet

export interface Wallet {
  id: string
  balance: number
  name: string
  default: boolean
  payment_method: PaymentMethod
  drivers: Driver[]
  records: {
    [month: string]: {
      [t: string]: WalletRecord[],
    },
  }

  [key: string]: any
}

export interface AvailablePaymentMethod {
  provider: string
  method: string
  notes: string
  logo: string
}

export interface PaymentMethod {
  id: string
  name: string
  brand?: string
  expiration?: string
  last4?: string
}

export interface ChangePaymentMethod {
  'payment_method_id': string,
  'wallet_id': string,
}

export interface DeletePaymentMethod {
  'payment_method_id': string,
}

export interface Card {
  name: string
  number: number
  cvv: number
  expiration_date: string
}

// Invoice

export interface Invoice {
  id: string
  number: number
  status: 'ON HOLD' | 'SENT' | 'PAID' | 'CANCELED' | 'OVERDUE' | 'AWAITING PAYMENT' | 'NO PAYMENT METHOD' | 'HOLDOVER PAYMENT',
  payed_on: string | null
  emission_date: string
  due_date: string
  tax: number
  subtotal: number
  total: number
  url: string
  type: 'PROFORMA' | 'INVOICE' | 'CREDIT NOTE',
  billing_info: BillingInfo
}

export interface Deposit {
  id: string
  status: string
  amount: number
  reason: string
  walletable_type: null
  walletable_id: null
  created_at: Date
}

export interface Credit {
  status: string // TODO: expand into the actual values
  name: string
  amount: number
}

export interface BillingInfo {
  client_id: number
  organization_name: string
  vat_number: string
  fiscal_code: string
  country: string
  region: string
  city: string
  postal_code: string
  street: string
}

export interface InvoicePay {
  wallet_id: string
  payment_method_id: string
}

// Subscriptions

export interface Subscription {
  id: string
  subscribed_drivers: number
  auto_renew: boolean
  plan: SubscriptionPlan
  drivers: Driver[]
  toc_id: string
  start: string
  end: string
  next_due_date: string
  start_timestamp: number
  end_timestamp: number
  next_due_date_timestamp: number
}

export interface SubscriptionPlan {
  id: string
  name: string
  title: string
  featured: boolean
  description: string
  cost: number
  status: 'AVAILABLE' | 'UNAVAILABLE' | 'DISABLED'
  booking_mode: string
  billing_cycle: number
  max_drivers: number
  duration: number
  valid_from: string
  valid_to: string
  toc_id: string
  rates: Rate[]
  valid_from_timestamp: number
  valid_to_timestamp: number
  renewable: boolean
  notice: string | null
  color: string | null
  picture: string | null
}

export type DriversByPlan = Partial<{
  [key: string]: string[]
  unassigned: string[]
}>

export interface Rate {
  id: string
  name: string
  plan: {
    name?: string
    id: string
  }
  vehicle_category: VehicleCategory
  booking_mode: string
  disabled: number
  valid_from: null
  valid_to: null
  info: RateInfo
}

export interface RateInfo {
  title: string | null,
  description: string
  time_rate: string | null
  distance_rate: string | null
  hidden: boolean
  usage_cost: number | null
}

// Reports

export interface Report {
  id: string
  type: Type
  status: string
  vehicle: Vehicle
  location: string
  description: string
  damage_position_raw: DamagePositionRaw[] | null
  position: Position
  attachments: any[]
  created: string
  updated: string
  created_timestamp: number
  updated_timestamp: number
}

export interface DamagePositionRaw {
  containerWidth: number
  containerHeight: number
  left: number
  top: number
}

export interface Type {
  id: string
  name: string
  description: string
  position_required: boolean
  attachment_required: boolean
}

export interface LoginByPhoneCodeRequest {
  mobile_number: string | null
}

export interface LoginByPhoneRequest {
  mobile_number: string | null
  token: number | string | null
}

export interface Highlight {
  title: string | null
  content: string | null
  subcontent: string | null
}

export enum FuelTypes {
  PETROL = 'PETROL',
  DIESEL = 'DIESEL',
  HYBRID = 'HYBRID',
  GAS = 'GAS',
  ELECTRIC = 'ELECTRIC',
  HYDROGEN = 'HYDROGEN',
  NONE = 'NONE',
  OTHER = 'OTHER',
}

export interface ModifyRequest {
  name: string
  surname: string
  phone: string
  email: string
  mobile: string
  fiscal_code: string
  vat_number: string
  organization_name: string
  address: Address
  billing_address: Address
  extra: Extra | any
}

export interface ExtraActionRequest {
  reservationNumber: number,
  operation: string
  data?: Record<string, any>
}

export interface ExtraActionResponse {
  result: string,
  operation: string
}

export interface Extra {
  actions: ExtraAction[]

  [key: string]: any
}

export enum ExtraAction {
  extend = 'extend',
  edit = 'edit',
  refuel = 'refuel',
  report_unavailable_parking = 'report_unavailable_parking'
}

export interface RecordRequest {
  id: string
  account_type: string
  month: string
}

export interface WalletRecord {
  id: string
  amount: number
  tax: number
  total: number
  pending: boolean
  type: string
  invoice_after: null | string
  reason: string[]
}

export interface InviteRequest {
  name: string
  surname: string
  email: string
}

export interface DriverEditRequest {
  title: string
  name: string
  surname: string
  birthdate: string | Date
  gender: string
  email: string
  phone: string
  mobile: string
  address: Address
}

export enum DocumentState {
  UNVERIFIED = 'UNVERIFIED',
  REFUSED = 'REFUSED',
  EXPIRED = 'EXPIRED',
  VALID = 'VALID',
}

export interface Document {
  id: string
  number?: string
  status: DocumentState
  expiry: string
  issuer: Issuer
  driver: Driver
  type: DocumentType
  attachments: Attachment[]
}

export interface Attachment {
  token: string
  mime: string
  url: string
}

export interface Issuer {
  by: string
  date: string
  country: string
}

export interface DocumentType {
  id: string
  name: string
  description: string
}

export interface TerminatePosition extends Position {
  timestamp: number
  altitude?: number
}

export interface RestHWRequest {
  position?: Position
  reservation_number: number
}

export interface OpenVehicleRequest extends RestHWRequest {
  pin: string | null
}

export interface TerminateRequest extends RestHWRequest {
  acceptUndesiderable?: boolean
  forceForbidden?: boolean
  user_declaration?: { [id: string]: boolean }[]
}

export interface TerminateResponse extends GenericResponse {
}

export interface RatesForCategoryRequest {
  planId: string,
  categoryId: string
}

export interface ReportRequest {
  vehicle_id: string,
  reservation_id: string | null,
  reporting_type_id: string | null,
  location: string | null,
  position: Position | null,
  description: string,
  attachments: string[],
  damage_position_raw: DamagePosition[],
}

export interface ReportResponse {
  id: string
  type: Type
  status: string
  vehicle: Vehicle
  location: string
  description: string
  damage_position_raw: DamagePosition[]
  position: Position
  attachments: any[]
  created: string
  updated: string
  created_timestamp: number
  updated_timestamp: number
}

export interface DamagePosition {
  containerWidth: number,
  containerHeight: number,
  left: number,
  top: number,
}

export interface DamagePoints {
  title: string
  left: number,
  top: number,
}

export interface ReportType {
  id: string | null,
  name: string | null,
  description: string | null,
  position_required: boolean | null,
  attachment_required: boolean | null,
}

export interface PaginatedRequest {
  per_page: number
  page_number: number
}

export interface ReservationPaginatedRequest extends PaginatedRequest {
  start?: string,
  end?: string,
  plate?: string
}

export interface PaginatedResponse<T> {
  current_page: number
  current_page_count: number
  items: T[]
  items_count: number
  page_count: number
  per_page_count: number
}

export type ReservationPaginatedResponse = PaginatedResponse<ReservationResponse>
export type BookingRequestPaginatedResponse = PaginatedResponse<BookingRequest>

export interface ReservationExtendRequest {
  reservation_number: number,
  end: string,
}

export interface ReservationEditRequest extends ReservationExtendRequest {
  start: string,
}

export interface CheckInOut extends ReservationEditRequest {
  start: string
  end: string
}

export interface BookingMesh {
  booking_mode: keyof typeof BookingMode
  vehicle_type: keyof typeof VehicleType
  vehicle_type_id: string
}

export interface UserMesh extends BookingMesh {
  available: boolean
}

export interface EstimateConfig {
  time?: string
  distance?: string
  total?: string
}

export interface FieldConfigs extends Record<string, any> {
  allowedLanguages?: string
  range_filter?: 'top' | 'icon' | 'hidden'
  fuel_type?: 'hidden'
  unavailable_offline_commands?: 'hidden'
  qr_booking_disabled?: boolean | string
  rest_disabled?: boolean | string
  home_menu_style?: string
  hide_vouchers?: boolean | string
  coupon_disabled?: boolean | string
  packets_enabled?: boolean | string
  topup_sizes?: string
  filters_range?: boolean | string
  filters_range_step?: number
  filters_range_max?: number
  filters_range_min?: number
  app_mode?: string
  estimate?: EstimateConfig
  invite_friend_enabled?: boolean | string
  activities_hidden_sections?: string
  activities_hidden_booking?: string
  hide_free_plan_string?: string
  hide_plan_duration?: string
  recover_pin_by_sms_enabled?: boolean | string
  filter_menu_style?: string
  check_operation_distance?: string
  map_filters?: string | 'hidden'
  map_zoom_level?: number
  hide_profile_button?: boolean
  upsell_packets?: boolean
  filters_meta_fields?: string
  splash_button_on_map?: boolean
  ev_charge_button_on_map?: boolean
  ev_charge_search_range?: number
}

export interface SplashButton {
  id: string
  title: string
  description: string
  action: string
  options: string | object
  color?: string
  icon?: string
  logged_in?: boolean
  large?: boolean | number // quite shit, find way to adapt to min size
  side_icon?: boolean
}

export interface SplashButtons {
  title?: string
  description?: string
  elements?: SplashButton[]
}

export interface AppConfig {
  parking_report_id: string | null
  default_language: string | null
  default_latitude: number
  default_longitude: number
  default_plan: string | null
  damage_report_id: string | null
  support_center_url: string
  android_store_url: string
  ios_store_url: string
  currency: string
  sms_login_enabled: boolean | undefined
  social_login_enabled: boolean | undefined
  round_price_values: boolean
  default_country_code: string
  extra_client_fields: ExtraClientField[]
  fields_configuration: FieldConfigs
  prepaid_wallet_enabled?: string | boolean
  time_slot_dimension: number
  web_app_url: string
  mandatory_profile_picture?: boolean
  registration_mode?: 'enabled' | 'disabled' | 'invitation'
  bm_reminder_threshold?: number
  currency_sign_after?: boolean
  space_after_currency_sign?: boolean
  distance_unit?: string
  space_before_distance_unit?: boolean
  enable_booking_request?: boolean
  date_format?: string
  time_format?: string
  datetime_format?: string
  splash_configuration?: SplashButtons[]
  login_providers?: string
}

export interface Globalnote {
  id: string
  description: string
}

export interface ExtraClientField {
  default: string | null
  validation: string
  value: string
}

export interface ProfileImageRequest {
  attachment_token: string
}

export interface AccountStatus {
  driver: boolean,
  client: boolean,
  payment_method: boolean,
  document: boolean,
  signed_tocs: boolean
  subscription: boolean
  driver_status?: 'missing' | 'pending_validation' | 'valid'
  client_status?: 'missing' | 'pending_validation' | 'valid'
  document_status?: 'missing' | 'pending_validation' | 'valid'

  [key: string]: boolean | string | undefined
}

export enum TimestampEnum {
  client = 'getClient',
  driver = 'getDriver',
  drivers = 'getDrivers',
  reservation = 'reservationHistory',
  invoice = '',
  subscription = 'getSubscriptions',
  documents = 'getDocuments',
  payment_methods = 'getPaymentMethods',
  wallet_records = '',
  plans = 'getPlans',
  reports = 'getReports',
  booking_requests = 'bookingRequestHistory'
}

export type Timestamps = {
  [K in `${keyof typeof TimestampEnum}_timestamp`]: number | null
} & {
  [K in keyof typeof TimestampEnum]: string
}

export interface Autorenew {
  subscription_id: string,
  status?: boolean
}

// ToCs

export interface TocResponse {
  type: TocType
  tocs: TocElement[]
}

export interface TocElement {
  status: 'SIGNED' | 'MISSING' | 'EXPIRED' | 'UPCOMING'
  signed_on: string | null
  document_date: string | null
  document_version: string | null
  toc: TocDetail
}

export interface Toc { // will be deprecated soon, only TocDetail will remain
  id: string
  content: string
  acceptance_statement: null | string
  link: null | string
}

export interface TocDetail extends Toc {
  id: string
  type: TocType
  name: null | string
  content: string
  link: null | string
  optional: boolean
  acceptance_statement: null | string
  required_from: null | string
  disabled: boolean
  version: string
  attachment: null | string
  attachment_translations?: Record<string, string> | null
}

export interface TocType {
  id: string
  name: string
  group: string
}

export interface TocSignResponse extends GenericResponse {
}

export type ZoneResponse = {
  [key in string | number]: Zone | null
} & {
  allowed: Zone | null
  undesiderable: Zone | null
  forbidden: Zone | null
}

export interface ServiceZone {
  id: string
  name: string
  zones: Zone[]
}

export interface Zone {
  id: string
  name: string
  color: string | null
  poly: Pos[][]
}

export interface Poi {
  id: string
  name: string
  display_text: string
  icon: string
  visibility?: 'normal' | 'important'
  data: PoiData[]
}

export interface PoiData {
  position: Pos
  description: string
  counter: number | string
}

export interface EnrichedPoiData extends PoiData {
  id: string
  name: string
  display_text: string
  icon: string
  visibility?: 'normal' | 'important'
  color?: string,
}

export interface FlowInputValidation {
  id?: string

  [key: string]: any
}

export interface FlowInputStep {
  id: string
  title?: string
  name?: string | null // for some reason
  description?: string
  image?: string | null
  hook?: string
  fields?: FlowInputField[]
  context?: string
  disable_back?: boolean
  disable_forward?: boolean
  skip?: boolean
  sleep?: number

  output?: string | null
  output_format?: { [k: string]: any } | null
  output_success?: string
  output_failure?: string
}

export interface FlowInputField {
  id: string
  name: string
  description?: string | null
  type:
    'address'
    | 'attachment'
    | 'checkbox'
    | 'country'
    | 'date'
    | 'dateRange'
    | 'dateTimeRange'
    | 'document'
    | 'email'
    | 'hidden'
    | 'image'
    | 'invite'
    | 'lang'
    | 'location'
    | 'login'
    | 'multipleChoice'
    | 'multipleInvoices'
    | 'name'
    | 'number'
    | 'parking_lots'
    | 'password'
    | 'paymentMethod'
    | 'phone'
    | 'phoneVerification'
    | 'qrcode'
    | 'radio_buttons'
    | 'sameAs'
    | 'select'
    | 'selfie'
    | 'string'
    | 'summary'
    | 'summaryButton'
    | 'surname'
    | 'toc'
    | 'tocs'
    | 'vehicle_cards'
    | 'verification'
  validation?: string | FlowInputValidation
  options?: string[] | Array<Record<string, any>> | unknown
  value?: any
  dynamic?: {
    input: string
    input_format: { [k: string]: any }
    mappings: string | { [key: string]: string }
    remap: 'id' | 'name' | 'description' | 'type' | 'validation' | 'options' | 'value'
    empty?: string
  }
  dynamic_parsed?: ['id' | 'name' | 'description' | 'type' | 'validation' | 'options' | 'value', any]
  groupWith?: string
}

export class RegistrationImage implements Attachment {
  public url = ''
  public mime = ''
  public token = ''
  public width = 512
  public height = 512
  public sent?: boolean
}

export interface RegistrationRequest {
  endpoint: string,
  payload: any
}

export interface Rating {
  mandatory: boolean
  reporting_type_id: string | null
  survey: string
  rating_threshold: number
}

export interface RatingRequest {
  reservation: number
  ratings: { [key: string]: number }
}

export interface CouponResponse {
  amount: number
  id: string
  reason: string
  tax: number
}

// profile v3

export interface Profile {
  driver: Driver
  data: ProfileData
}

export interface ProfileData {
  wallet?: ProfileItem
  subscriptions?: ProfileItem
  drivers?: ProfileItem
  payment_methods?: ProfileItem
  documents?: ProfileItem
  reportings?: ProfileItem
  tocs?: ProfileItem
  shop?: ProfileItem

  [key: string]: ProfileItem | undefined
}

export interface ProfilePatchRequest {
  fiscal_code?: string
  birthdate?: string
  birthplace?: string
}

export interface ProfileItem {
  status: 'FAIL' | 'OK' | 'WARN'
  note: null | string
  flow: null | string
  meta: ProfileItemMeta | WalletMeta
}

export interface ProfileItemMeta {
  count?: null | string | number
  format?: string
  icon?: string

  [key: string]: any
}

export interface WalletMeta extends ProfileItemMeta {
  balance_uninvoiced_records: number
  balance_uninvoiced_deposits: number
  balance_unpaid_invoices: number
  balance_promo_credits: number
  balance_paid_credits: number
}

export interface Attribute {
  id: string
  name: string
  slug: string
  description: string
  recommended: number
  value: string
  type: string
  icon?: string
}

export interface Theme {
  options?: Options
  primary: string
  secondary: string
  accent: string
  error: string
  info: string
  success: string
  warning: string

  buttonDefaultColor: string
  buttonDefaultColorOverride?: string

  topBarTop?: string
  topBarBottom?: string
  topBarContrastOverride?: 'dark' | 'light' | string

  profileTop?: string
  profileBottom?: string
  profileContrastOverride?: 'dark' | 'light' | string

  walletTop: string
  walletBottom: string
  walletContrastOverride?: 'dark' | 'light' | string

  topUpTop: string
  topUpBottom: string
  topUpContrastOverride?: 'dark' | 'light' | string

  couponTop: string
  couponBottom: string
  couponContrastOverride?: 'dark' | 'light' | string

  planTop: string
  planBottom: string
  planContrastOverride?: 'dark' | 'light' | string

  planDefaultTop?: string
  planDefaultBottom?: string
  planDefaultContrastOverride?: 'dark' | 'light' | string

  documentTop: string
  documentBottom: string
  documentContrastOverride?: 'dark' | 'light' | string

  tocTop: string
  tocBottom: string
  tocContrastOverride?: 'dark' | 'light' | string

  overviewWalletTop: string
  overviewWalletBottom: string
  overviewWalletContrastOverride?: 'dark' | 'light' | string

  invoiceTop: string
  invoiceBottom: string
  invoiceContrastOverride?: 'dark' | 'light' | string

  missionTop: string
  missionBottom: string
  missionContrastOverride?: 'dark' | 'light' | string

  'FFVAN': string
  'FFMINIBUS': string
  'FFCAR': string
  'FFAMI': string
  'FFMICRO': string
  'FFSCOOTER': string
  'FFBIKE': string
  'FFKICKSCOOTER': string

  'RTVAN': string
  'RTMINIBUS': string
  'RTCAR': string
  'RTAMI': string
  'RTMICRO': string
  'RTSCOOTER': string
  'RTBIKE': string
  'RTKICKSCOOTER': string

  'STRVAN': string
  'STRMINIBUS': string
  'STRCAR': string
  'STRAMI': string
  'STRMICRO': string
  'STRSCOOTER': string
  'STRBIKE': string
  'STRKICKSCOOTER': string

  'OWFFVAN': string
  'OWFFMINIBUS': string
  'OWFFCAR': string
  'OWFFAMI': string
  'OWFFMICRO': string
  'OWFFSCOOTER': string
  'OWFFBIKE': string
  'OWFFKICKSCOOTER': string

  'CRTCAR': string
  'RTPLATFORM': string
  'PARKING': string
}

export interface Options {
  customProperties: boolean
}

export interface rangeListItem {
  available: boolean,
  selected: boolean
  timestamp: number
}

export type RangeTuple = [number | null, number | null]

export enum HomeModeEnum {
  'map' = 'map',
  'mission' = 'mission',
  'listing' = 'listing',
  'light' = 'light',
  'login' = 'login',
  'splash' = 'splash'
}

export interface CheckupResponse {
  [action: string]: Survey[]
}

export interface Survey {
  survey: string
  type: string // 'report', 'boolean', 'rating'
  mandatory: boolean
  needs_report: string | null | boolean
  text: string
  text_yes: string | null
  text_no: string | null

  _has_rating: boolean | undefined
  _has_report: boolean | undefined
}

export interface CheckItem {
  id: string
  title: string
  description?: string
  icon?: string
}

export interface CheckpointRequest {
  device: Device
  useragent: string
  appversion: string
  release: string
}

export enum CheckpointStatus {
  'ok' = 'ok',
  'needs-update' = 'needs-update',
  'suggest-update' = 'suggest-update'
}

export interface CheckPointResponse {
  'status': CheckpointStatus
  'update_text'?: string
  'current_level'?: number
  'current_release'?: string
  'android_url'?: string
  'ios_url'?: string
  'browser_url'?: string
}

export interface PositionData {
  latitude: number
  longitude: number
  accuracy: number
  timestamp: string
}

export interface OfflineHookData {
  timestamp: string
  event: string
  fuel_level?: number | undefined
  odometer?: number | undefined
  door_status?: string | undefined
  engine_status?: string | undefined
  position?: PositionData | undefined
}

export interface ReverseGeocoderResponse extends Position {
  result: string
}

export interface OfflineHookData {
  timestamp: string
  event: string
  fuel_level?: number
  odometer?: number
  door_status?: string
  engine_status?: string
  position?: PositionData
}

export interface PositionData {
  latitude: number
  longitude: number
  accuracy: number
  timestamp: string
}

export interface InterventionRequestRequest {
  latitude: number,
  longitude: number,
  parking_lot_id?: string,
  zone_name?: string
  vehicle_type?: keyof typeof VehicleType
}

export interface InterventionRequest {
  action: string
  assigned_parking_lot: string
  address: string | null
  distance: number
  plate: string
  fuel_level: string
  reason: string
  score: number
  vehicle_type?: keyof typeof VehicleType
  zone?: string
}

export interface InterventionParkingLot {
  distance: number
  parking_lot: {
    id: string
    name: string
    picture: string
    description: string | null
    position: Position
  };
}

export interface NewPasswordRequest {
  new_password: string
  new_password_retype: string
}

export interface ChangePasswordRequest extends NewPasswordRequest {
  old_password: string
}

export interface ChangePasswordResponse extends GenericResponse {
}

export interface ChangePinRequest {
  password: string,
  old_pin: string,
  new_pin: string,
}

export interface ChangePinResponse extends GenericResponse {
}

export interface MapMarker<T = VehicleSlot> {
  items: T[]
  key: string
  icon: string
  distance: number | null
  position: Pos
}

export interface TopupResponse {
  status: string
  amount: number
}

export interface Packet {
  id: string,
  name: string,
  description: string | null,
  amount: number,
  minutes: number | null,
  distance: number | null,
  duration: number | null,
  max_uses: number | null,
  vehicle_categories: VehicleCategory[]
}

export interface ActivePacket {
  id: string,
  minutes: number,
  remaining_minutes: number,
  distance: number,
  remaining_distance: number,
  max_uses: number,
  remaining_uses: number,
  valid_from: string,
  valid_to: string
}

export interface Page {
  action?: string
  button_labels?: string
  content: string
  title: string
}

export interface VerificationMethodResponse {
  provider: string,
  payload: {
    [key: string]: any
    url: string
  }
}

export interface EVChargingStation {
  id: number | string,
  meta: {
    name?: string,
    network_id?: string
    partner?: string
  },
  address: string,
  lat: number,
  lng: number,
  distance: number,
  ports: EVChargingPort[]
}

export interface EVChargingPort {
  portId: number | string,
  plugType: 'CCS1' | 'CCS2' | 'CHADEMO' | 'TESLA' | 'TYPE1' | 'TYPE2' | string
  maxPower: number,
  status: 'AVAILABLE' | string,
  portCode: string
}

export interface EVChargingStationRequest {
  lat: number,
  lng: number,
  radius: number
}

export interface Notification {
  id: string,
  account: false, // client, is main driver
  subject: string,
  content: string,
  url: string | null,
  read: boolean,
  timestamp: string // date, not a timestamp
}

export type LoginProviderName = 'grandid' | string

export interface LoginProvider {
  id: LoginProviderName
  name: string
  icon: string
  color: string
}

export interface LoginProviderResponse {
  token?: string
  auth_url: string
}

export interface SocialLoginResponse {
  status: string
  token?: string
  email?: string
  flow_open?: string
  flow_data?: Record<string, any>
}
