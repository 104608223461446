





import {Component, Vue, Watch} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {Dialog, FieldConfigs, HomeModeEnum} from '@/lib/kepler/interfaces'

import Map from '@/views/Map.vue'
import CorporateActivities from '@/views/CorporateActivities.vue'
import ListingHomeMode from '@/views/ListingHomeMode.vue'
import LightActivities from '@/views/LightActivities.vue'
import Flow from '@/components/flow/Flow.vue'
import HomeMenu from '@/components/HomeMenu.vue'
import {MenuState} from '@/store/modules/menu'
import {FlowInputsState} from '@/store/modules/flowInputs'
import {Component as VueComponent} from 'vue/types/options'
import Login from '@/views/Login.vue'
import Loader from '@/components/Loader.vue'
import Splash from '@/views/Splash.vue'

interface Mode {
  key: string,
  component: VueComponent,
  props: Record<string, any>
}

@Component({
  components: {
    HomeMenu,
  },
  name: 'Home',
})
export default class Home extends Vue {
  @State((state) => state.configuration.homeMode) public homeMode!: HomeModeEnum
  @State((state) => state.flowInputs) public flows!: FlowInputsState
  @State('menu') public homeMenu!: MenuState
  @State('dialogState') public dialogState!: Dialog[]
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  @Getter('isLogged') public isLogged!: boolean
  @Action('getFlows') public getFlows!: () => Promise<FlowInputsState>
  @Getter('popupIsOpen') public popupIsOpen!: number
  @Action('setHomeMode') public setHomeMode!: (mode: HomeModeEnum) => void
  @Action('setTopbarHidden') public setTopbarHidden!: (v: boolean) => void

  public mode: Mode | null = null

  /* tslint:disable: cyclomatic-complexity */
  public getComponent(mode: string, flows: FlowInputsState): Mode | null {
    if (mode in HomeModeEnum) {
      return this.switchEnum(mode) ?? null
    }
    if (mode.startsWith('flow|') && flows) {
      const name = mode.split('|')[1]
      const fallback = mode.split('|')[2]
      if (name in flows) {
        this.setTopbarHidden(true)
        return {
          component: Flow, key: 'flow_' + name, props: {
            flowName: name,
            steps: flows[name].steps,
            initialLoading: null,
            exitForward: null,
          },
        }
      } else if (fallback) {
        return this.getComponent(fallback, flows)
      }
    }
    if (mode.startsWith('route|')) {
      const name = mode.split('|')[1]
      const fallback = mode.split('|')[2]
      const matched = this.$router.getMatchedComponents({name})
      if (matched.length) {
        return {component: matched[0] as VueComponent, key: 'route_' + name, props: {}}
      } else if (fallback) {
        return this.getComponent(fallback, flows)
      }
    }
    return null
  }

  public switchEnum(mode: string): Mode | undefined {
    switch (mode) {
      case HomeModeEnum.map:
        return {component: Map, key: 'home_map', props: {}}
      case HomeModeEnum.mission:
        return {component: CorporateActivities, key: 'home_mission', props: {}}
      case HomeModeEnum.light:
        return {component: LightActivities, key: 'home_light', props: {}}
      case HomeModeEnum.listing:
        return {component: ListingHomeMode, key: 'home_listing', props: {}}
      case HomeModeEnum.splash:
        if (this.$isLogged()) {
          return {component: Splash, key: 'home_splash', props: {topbar: false, canDismiss: false}}
        } else {
          this.setTopbarHidden(true)
          return {component: Login, key: 'login', props: {}}
        }
      case HomeModeEnum.login:
        this.setTopbarHidden(true)
        return {component: Login, key: 'login', props: {}}
    }
  }

  public created() {
    const conf = this.fieldConfigs?.app_mode
    const env = this.$env.FORCE_MAP_MODE
    const empty = !(conf || env)
    if (empty && this.homeMode !== HomeModeEnum.map) {
      this.setHomeMode(HomeModeEnum.map)
    }
    if (env) {
      this.setHomeMode(env as HomeModeEnum)
      return
    }
    if (conf && conf !== this.homeMode) {
      this.setHomeMode(conf as HomeModeEnum)
    }

    if (!Object.keys(this.flows).length) {
      this.updateWithCurrentFlows()
    } else {
      this.mode = this.getComponent(this.homeMode, this.flows)
    }
  }

  public updateWithCurrentFlows() {
    this.mode = {key: 'loader', component: Loader, props: {}}
    this.getFlows().then((r) => {
      this.mode = this.getComponent(this.homeMode, r)
    })
  }

  @Watch('isLogged')
  public onLogChange() {
    if (this.homeMode.startsWith('flow|')) {
      this.updateWithCurrentFlows()
    } else {
      this.mode = this.getComponent(this.homeMode, this.flows)
    }
  }

  @Watch('homeMode', {immediate: true})
  public switchHomeMode(mode?: HomeModeEnum) {
    this.setHomeMode(mode ? mode : HomeModeEnum.map)
    this.mode = this.getComponent(this.homeMode, this.flows)
  }
}
