














import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
// import {LottieWrapperOptions} from '@/components/Lottie.vue'
// import data from '../../public/img/parallax/animation.json'

@Component({
  name: 'LoopingBG',
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Img: Utils.loadComponent('proxy/Image'),
    // LottieWrapper: Utils.loadComponent('Lottie'),
  },
})
export default class LoopingBG extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) private logo!: boolean

  private height: number = 0
  private width: number = 0
  // private lottieOptions: LottieWrapperOptions = {
  //   animationData: data,
  //   autoplay: true,
  //   loop: false,
  //   rendererSettings: {
  //     progressiveLoad: true,
  //     // viewBoxOnly: true,
  //     preserveAspectRatio: 'xMidYMid slice',
  //     className: 'some-css-class-name',
  //   },
  // }

  private onResize() {
    this.height = this.$el.clientHeight
    this.width = this.$el.clientWidth
  }
}
