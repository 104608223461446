





























import {Component, Prop} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import Utils from '../../utils'
import {EventBus} from '@/main'
import moment from 'moment'
import {AvailabilityRequest, AvailabilityResponse, BookTimeSelectRequest, VehicleSlot} from '@/lib/kepler/interfaces'
import VehicleBookingDateTimeSelect from './VehicleBookingDateTimeSelect.vue'
import VehicleBookingAddMemoDialog from '@/views/Vehicle/VehicleBookingAddMemoDialog.vue'
import QuickBookDialog from '@/components/QuickBookDialog.vue'
import ServiceMesh from '@/lib/serviceMesh'
import {VSpacer} from 'vuetify/lib'
import sdk from '@/lib/kepler/sdk'
import ChecklistDialogCallback from '@/views/ChecklistDialogCallback.vue'
import {mixins} from 'vue-class-component'
import BookingMixin from '@/lib/BookingMixin'

@Component({
  components: {
    FuelLevel: Utils.loadComponent('FuelLevel'),
    StatusLabel: Utils.loadComponent('StatusLabel'),
    VSpacer,
    Card: Utils.loadComponent('proxy/Card/Card'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    BookingButtons: Utils.loadComponent('BookingButtons'),
    VehicleQuickInfo: Utils.loadComponent('entities/vehicle/VehicleQuickInfo'),
    VehicleDetail: Utils.loadComponent('entities/vehicle/VehicleDetail'),
  },
  name: 'Vehicle',
  mixins: [BookingMixin],
})

export default class Vehicle extends mixins<BookingMixin>(BookingMixin) {
  @State((state) => state.configuration.appConfig.time_slot_dimension) public timeSlot!: any

  @Action('setZone') public setZone!: (id: string) => Promise<void>
  @Action('vehicleAvailability') public vehicleAvailability: any

  @Prop({required: true, type: Object}) public vehicleSlot!: VehicleSlot

  protected availability: AvailabilityResponse[] = []
  protected availabilityString: string | null = null
  protected availabilityColor: string = 'grey'
  protected needsReminder: boolean = false

  protected loading: boolean = true

  protected get vehicleTypeColor() {
    const key = `${this.vehicleSlot.reservation_type}${this.vehicleSlot.vehicle.category.type}`.toUpperCase()
    return {color: ServiceMesh.colors[key]}
  }

  public created() {
    this.setZone(this.vehicleSlot.id)
    this.bookRequest.plate = this.vehicleSlot.vehicle.plate
  }

  public mounted() {
    if (sdk.people.isLogged()) {
      this.getAvailability().finally(() => {
        this.getAvailabilityString()
        this.loading = false
      })
    }
  }

  protected destroyed() {
    EventBus.$off('quickBookingDateSelected')
  }

  protected book() {
    return this.$dialog.open(VehicleBookingAddMemoDialog, {
      props: {
        vehicleSlot: this.vehicleSlot,
        bookRequest: this.bookRequest,
        confirmCallback: () => {
          this.sendBooking((r) => {
            const id = r?.id || ''
            this.$router.push({name: 'reservation', params: {id}})
          })
        },
      },
    })
  }

  protected booking() {
    this.$dialog.open(VehicleBookingDateTimeSelect, {
      props: {
        vehicleSlot: this.vehicleSlot,
        bookRequest: this.bookRequest,
        confirmCallback: this.book,
      },
    })
  }

  protected quickBooking() {
    this.loading = true

    const openQuickBooking = () => {
      this.$dialog.open(QuickBookDialog, {
        props: {
          vehicleSlot: this.vehicleSlot,
          availability: this.availability,
          onConfirm: ((req: BookTimeSelectRequest) => {
            this.bookRequest.start = req.start
            this.bookRequest.end = req.end
            this.$dialog.close()
            this.book()
          }),
        },
      })
    }
    const availabilityStillValid = moment().isBefore(this.availability[0].end)

    if (availabilityStillValid) {
      openQuickBooking()
      this.loading = false
    } else {
      this.getAvailability().then(openQuickBooking).finally(() => {
        this.loading = false
      })
    }
  }

  protected remindIfNeeded() {
    const bm = this.vehicleSlot.reservation_type

    return new Promise<void>((callback) => {
      const str = 'vehicle.booking_mode_reminder'
      const title = this.$isAvailable(`${str}.${bm}.title`)
      const subtitle = this.$isAvailable(`${str}.${bm}.subtitle`)

      if (this.needsReminder && (title || subtitle)) {
        this.$dialog.open(ChecklistDialogCallback, {
          props: {
            title,
            subtitle,
            callback,
            allRequired: true,
            confirmColor: this.vehicleTypeColor.color,
            confirmText: this.$t('common.continue'),
            options: [{text: this.$t(`${str}.${bm}.confirm`), value: bm}],
          },
        })
      } else {
        callback()
      }
    })
  }

  protected getAvailability() {
    const now = moment()
    const start = now
      .minute(now.minute() - now.minute() % this.timeSlot)
      .seconds(0)
      .format('YYYY-MM-DD HH:mm:ss')
    const end = now
      .add(2, 'days')
      .format('YYYY-MM-DD HH:mm:ss')
    const ar: AvailabilityRequest = {
      vehicle_id: this.vehicleSlot.vehicle.id,
      start,
      end,
    }
    return this.vehicleAvailability(ar).then((r: AvailabilityResponse[]) => {
      this.availability = r
      this.availabilityString = this.getAvailabilityString()
    })
  }

  protected getAvailabilityString() {
    const formatString = (n: number) => {
      return moment.unix(this.availability[n].start_timestamp).calendar()
    }

    const countWhile = (value: boolean, len: number) => {
      let n = 1
      while (!!this.availability[n]?.available === value && n <= len) {
        n++
      }
      return n
    }

    const l = this.availability?.length
    const first = this.availability[0]?.available

    if (l && first) {
      const n = countWhile(true, l)
      if (n < l) {
        this.availabilityColor = 'warning'
        return this.$t('status.vehicle.available_to', {t: formatString(n)})
      } else {
        this.availabilityColor = 'success'
        return this.$t('status.vehicle.available')
      }
    } else if (l && !first) {
      const n = countWhile(false, l)
      if (n < l) {
        this.availabilityColor = 'warning'
        return this.$t('status.vehicle.available_from', {t: formatString(n)})
      } else {
        this.availabilityColor = 'error'
        return this.$t('status.vehicle.unavailable')
      }
    }
    return null
  }
}
