import { render, staticRenderFns } from "./CardReservation.vue?vue&type=template&id=34733252&lang=pug&"
import script from "./CardReservation.vue?vue&type=script&lang=ts&"
export * from "./CardReservation.vue?vue&type=script&lang=ts&"
import style0 from "./CardReservation.vue?vue&type=style&index=0&id=34733252&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports