import moment from 'moment'
import {$timeformat, $datetimeformat} from '@/lib/plugins/lang'

export default class DateHelper {

  private static duration?: moment.Duration

  public static parse(date: string, format?: string | string[]) {
    return moment(date, format)
  }

  public static formatTimeOffset(format: string, seconds?: number) {

    const t = typeof seconds === 'number' ? moment.duration(seconds, 'seconds') : this.duration?.clone()
    if (!t?.isValid()) {
      throw new Error('invalid value')
    }
    const neg = t.asSeconds() < 0

    switch (format) {
      case 'mdhm':
        let mdhm = neg ? '-' : ''
        t.abs()
        if (t.asMonths() >= 1) {
          mdhm += `${Math.floor(t.asMonths())}M `
        }
        if (t.asDays() >= 1) {
          mdhm += `${Math.floor(t.days())}d `
        }
        if (t.asHours() >= 1) {
          mdhm += `${Math.abs(t.hours())}h `
        }
        mdhm += `${Math.abs(t.minutes())}m `
        return mdhm
      case 'dhm':
        let dhm = neg ? '-' : ''
        t.abs()
        if (t.asDays() >= 1) {
          dhm += `${Math.floor(t.asDays())}d `
        }
        if (t.asHours() >= 1) {
          dhm += `${Math.abs(t.hours())}h `
        }
        dhm += `${Math.abs(t.minutes())}m `
        if (t.asHours() < 1) {
          dhm += `${Math.abs(t.seconds())}s`
        }
        return dhm
      case 'months':
        return `${t.months()}m`
      case 'days':
        return `${t.days()}d`
      case 'hours':
        return `${t.hours()}h`
      case 'minutes':
        return `${t.minutes()}m`
      default:
        throw new Error('unsupported format')
    }
  }

  public static now(format: string | undefined = 'YYYY-MM-DD') {
    return moment().format(format)
  }

  public static getTimestamp() {
    return Math.round(new Date().valueOf() / 1000)
  }

  public static formatDate(date: string, format: string) {
    return moment(date).format(format)
  }

  // compares a date to current time and set the minutes of given date to "half past to" or to "o'clock"
  public static setToHalfPastOrToOClock(date: moment.Moment) {
    const now = this.parse(this.now('HH:mm:ss'))
    if (now.minutes() < 30) {
      date.minutes(0)
    } else {
      date.minutes(30)
    }
    return date
  }

  public static equalsToday(date: moment.Moment) {
    return this.now() === date.format('YYYY-MM-DD')
  }

  public static humanizeDuration(val: number, unit: any) {
    return moment.duration(val, unit).humanize()
  }

  public static parseMinutes(scope: any, val: number): string {
    if (val < 1440) {
      switch (val) {
        case 1:
          return `${scope.$t('common.time_units.minute')}`
        case 60:
          return `${scope.$t('common.time_units.hour')}`
        default:
          if (!(val % 60)) {
            return scope.$t('common.time_units.hours', {n: val / 60})
          } else {
            return scope.$t('common.time_units.minutes', {n: val})
          }
      }
    } else {
      const d = val / 1440
      switch (d) {
        case 1:
          return scope.$t('common.time_units.day')
        case 7:
          return scope.$t('common.time_units.week')
        case 30:
          return scope.$t('common.time_units.month')
        case 60:
          return scope.$t('common.time_units.two-month')
        case 90:
          return scope.$t('common.time_units.quarter')
        case 365:
          return scope.$t('common.time_units.year')
        default:
          if (!(d % 30)) {
            return scope.$t('common.time_units.months', {n: d / 30})
          } else if (!(d % 7)) {
            return scope.$t('common.time_units.weeks', {n: d / 7})
          } else {
            return scope.$t('common.time_units.days', {n: d})
          }
      }
    }
  }

  public static calendar(timestamp: string | number) {
    const m = moment(timestamp)
    if (m.isValid()) {
      const tf = $timeformat()
      const dtf = $datetimeformat()
      return m.calendar({
        sameDay: `[Today at] ${tf}`,
        nextDay: `[Tomorrow at] ${tf}`,
        lastDay : `[Yesterday at] ${tf}`,
        nextWeek: `dddd [at]${tf}`,
        lastWeek: `[last] dddd [at] ${tf}`,
        sameElse: dtf
      })
    }
  }
}
