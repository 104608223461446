






























import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'
import {EVChargingPort, EVChargingStation} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    CardButton: Utils.loadComponent('CardButton'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
})
export default class EVChargingCardPopup extends Vue {
  @Prop({type: Object, required: true}) public station!: EVChargingStation
  protected available(p: EVChargingPort) {
    return p.status === 'AVAILABLE'
  }
}
