import {ActionTree, MutationTree} from 'vuex'

import {
  BookingRequest,
  BookingRequestPaginatedResponse,
  PaginatedRequest,
} from '@/lib/kepler/interfaces'
import {RootState} from '@/store'
import sdk from '@/lib/kepler/sdk'

export class BookingRequestState implements BookingRequestPaginatedResponse {
  public current_page: number
  public current_page_count: number
  public items: BookingRequest[]
  public items_count: number = -1
  public page_count: number = -1
  public per_page_count: number = -1
  public last_request: number = 0

  constructor() {
    this.current_page = -1
    this.current_page_count = -1
    this.items = []
  }
}

const mutations: MutationTree<BookingRequestState> = {
  BOOKING_REQUESTS(requests, payload: BookingRequestPaginatedResponse) {
    requests.current_page = payload.current_page
    requests.current_page_count = payload.current_page_count
    payload.items.forEach((item) => {
      requests.items.push(item)
    })

    requests.items_count = payload.items_count
    requests.page_count = payload.page_count
    requests.per_page_count = payload.per_page_count
    requests.last_request = Math.floor(Date.now() / 1000)
  },
  BOOKING_REQUESTS_FLUSH(state) {
    Object.assign(state, new BookingRequestState())
    state.items = [] // just in case
  },
}

const actions: ActionTree<BookingRequestState, RootState> = {
  init({commit}) {
    commit('BOOKING_REQUESTS_FLUSH')
  },
  purge({commit}) {
    commit('BOOKING_REQUESTS_FLUSH')
  },
  bookingRequestHistory({rootGetters, state, commit}, payload?: PaginatedRequest) {
    return new Promise((resolve) => {
      if (rootGetters.appConfig.enable_booking_request) {
        const now  =  Math.floor(Date.now() / 1000)
        if (now - state.last_request > 5) {
          sdk.booking.getRequests(payload || {per_page: 20, page_number: 1})
            .then((response) => {
              commit('BOOKING_REQUESTS', response.data)
              resolve(response.data)
            })
        }
      } else {
        commit('BOOKING_REQUESTS_FLUSH')
        resolve(null)
      }
    })
  },
  flushAndReloadBookingRequestHistory({state, dispatch, commit}, skipDebounce = false) {
    const now  =  Math.floor(Date.now() / 1000)
    if (now - state.last_request > 5 || skipDebounce) {
      commit('BOOKING_REQUESTS_FLUSH')
      return dispatch('bookingRequestHistory')
    }
  },
  deleteBookingRequest({}, id: string) {
    return sdk.booking.deleteRequest(id)
  },
}

export default {
  state: new BookingRequestState(),
  mutations,
  actions,
}
