





















import {Component, Prop} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {TopbarButton} from '@/store/modules/topbar'
import {BookingRequest, PaginatedRequest, ReservationResponse} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import {BookingRequestState} from '@/store/modules/bookingRequests'
import InfiniteScrollHandler, {infiniteHandlerState} from '@/lib/InfiniteScrollHandler'
import {mixins} from 'vue-class-component'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    BookingRequestCard: Utils.loadComponent('entities/reservation/BookingRequestCard'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    InfiniteLoading: Utils.loadComponent('infiniteScroll/InfiniteLoading'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    EmptyList: Utils.loadComponent('EmptyList'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
  },
})
export default class BookingRequestHistory extends mixins(InfiniteScrollHandler) {
  @State('bookingRequests') public bookingRequests!: BookingRequestState
  @Action('refreshHistory') public refreshHistory!: () => void
  @Action('setActionButtons') public setActionButtons!: (buttons: TopbarButton[]) => void
  @Action('bookingRequestHistory') public bookingRequestHistory!: (req?: PaginatedRequest) => Promise<any>
  @Action('flushAndReloadBookingRequestHistory') public flushAndReloadBookingRequestHistory!: (skip?: boolean) => Promise<any>

  @Prop({
    type: Number,
    default: Infinity,
  }) public max!: number
  @Prop({
    type: Boolean,
    default: true,
  }) public reloadButton!: boolean
  @Prop({
    type: Function || null,
    default: null,
  }) public action!: () => void

  protected isLoading: boolean = false
  protected loading: boolean = true

  protected mounted() {
    if (this.max !== Infinity) {
      this.flushAndReloadBookingRequestHistory().finally(() => {
        this.loading = false
      })
    } else {
      this.flushAndReloadBookingRequestHistory().finally(() => {
        this.loading = false
      })
    }

    if (this.reloadButton) {
      const buttons: TopbarButton[] = [{
        icon: 'mdi-refresh',
        action: () => {
          this.reloadHistory()
        },
      },
      ]
      this.setActionButtons(buttons)
    }
  }

  protected reloadHistory(force?: boolean) {
    this.loading = true
    const infinite = (this.$refs.infiniteLoader as any)
    infinite.isLoading = true
    return this.flushAndReloadBookingRequestHistory(force).finally(() => {
      this.loading = false
      infinite.isLoading = false
    })
  }

  protected reload() {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true
      this.bookingRequestHistory().then((r) => {
        this.isLoading = false
        this.bookingRequests = r
      }).catch(reject)
    })
  }

  protected infiniteHandler(state: infiniteHandlerState) {
    if (this.bookingRequests.current_page >= 0) {
      this.handler(state, this.max, this.bookingRequests, this.bookingRequestHistory)
    }
  }

  protected handleClick(r: BookingRequest) {
    // no action for now
  }

}
