

























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {VIcon, VLayout, VSelect} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'
import Button from '@/components/Button.vue'
import {FlowInputStep} from '@/lib/kepler/interfaces'
import {EventBus} from '@/main'
import Layout from '@/components/proxy/Layout.vue'

@Component({
  components: {Layout, VLayout, Button, VSelect, VIcon, ValidationProvider},
  name: 'RadioButtons',
})
export default class RadioButtons extends Vue {
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules: any

  @Prop({
    type: String,
    default: null,
  }) public readonly value!: string | null

  @Prop({
    type: Array,
    default: () => [],
  }) public readonly items!: Array<{
    value: string,
    text: string,
    description?: string,
    color?: string,
    icon?: string,
  }>

  @Prop() public step?: FlowInputStep
  @Prop({
    type: String,
    required: false,
  }) public name?: string
  @Prop({
    type: String,
    required: false,
  }) public description?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public disabled?: boolean

  public innerValue: string | null = null
  public loading: boolean = false

  @Watch('innerValue')
  public onInnerValueChange(newVal: any) {
    if (newVal !== this.value) {
      this.$emit('input', newVal)
    }
  }

  @Watch('value')
  public onValueChange(newVal: string) {
    this.innerValue = newVal
  }

  public setValue(value: string) {
    this.innerValue = value
    if (this.step?.disable_forward && !this.disabled) {
      this.disabled = true
      this.loading = true
      EventBus.$emit('flowStepForward')
    }
  }

  protected created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
