






import {Component, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {EventBus} from '@/main'
import {FlowInputsState} from '@/store/modules/flowInputs'
import {AppConfig, HomeModeEnum, SplashButton} from '@/lib/kepler/interfaces'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import FlowDialog from '@/views/Flows/FlowDialog.vue'
import Loader from '@/components/Loader.vue'
import Splash from '@/views/Splash.vue'

@Component({
  components: {FlowAutoload, Loader},
})
export default class ConfigLoadedView extends Vue {
  @State((state) => state.profile.language) public lang!: string
  @State('flowInputs') public flowInputs!: FlowInputsState
  @State((state) => state.configuration.homeMode) public homeMode!: HomeModeEnum
  @State((state) => state.profile.showSplash) public showSplash!: boolean

  @Action('loadConfig') public loadConfig!: () => Promise<AppConfig>
  @Action('getFlows') public getFlows!: () => Promise<void>
  @Getter('autoOpenQueue') public autoOpenQueue!: Array<keyof typeof FlowInputsState>
  @Getter('splashButtons') public splashButtons!: SplashButton[]
  @Getter('popupIsOpen') public popupIsOpen!: number
  @Getter('dialogIsOpen') public dialogIsOpen!: number
  @Getter('isOnline') public isOnline!: boolean

  protected isConfigLoaded: boolean = false

  protected created() {
    EventBus.$off('login', this.resetConfig)
    EventBus.$on('login', this.resetConfig)
    this.init()
  }

  /* tslint:disable: cyclomatic-complexity */ // probably needs refactoring
  protected async init() {
    if (!this.isOnline) {
      this.isConfigLoaded = true
      return
    }

    if (this.$route.name === 'home') {
      const results = this.lang ?
        await Promise.all([this.loadConfig(), this.getFlows()]) :
        await Promise.all([this.loadConfig()])
      const r = results[0]

      if (!this.lang) {
        this.$langLoader(r.default_language ?? undefined)
        return
      }

      const flowName = this.autoOpenQueue[0]
      if (!this.popupIsOpen && !this.dialogIsOpen) {
        if (flowName && !!this.flowInputs[flowName]?.steps.length) {
          this.openAutoFlow(flowName)
        } else if (this.$isLogged() && this.showSplash && this.splashButtons?.length && !this.homeMode.startsWith('splash')) {
          await this.$popup.open(Splash, {hideTopbar: true})
        }
      }
      this.isConfigLoaded = true
    } else {
      this.isConfigLoaded = true
    }
  }

  protected openAutoFlow(flowName: string) {
    const flow = this.flowInputs[flowName]
    if (flow.dialog) {
      this.$dialog.open(FlowDialog, {props: {flowName}, hideTopbar: true})
    } else {
      this.$popup.open(FlowAutoload, {props: {flowName}, hideTopbar: true})
    }
  }

  protected beforeDestroy() {
    EventBus.$off('login', this.resetConfig)
  }

  protected resetConfig() {
    this.isConfigLoaded = false
    this.init()
  }
}
