





































import {Vue, Component, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import {Action, State} from 'vuex-class'
import sdk from '@/lib/kepler/sdk'

import {
  AppConfig,
  BookRequest,
  BookTimeSelectRequest,
  EstimateCategories,
  Pos,
  SearchRequest,
  UserMesh,
  VehicleCategory,
  VehicleSlot,
  BookingMode,
} from '@/lib/kepler/interfaces'
import Locate from '@/lib/location'
import VehicleBookingSummary from '../components/entities/vehicle/VehicleBookingSummary.vue'
import moment from 'moment'

class PositionClass {
  public lat: number
  public lng: number
  public acc: number | null

  constructor(lat?: number, lng?: number, acc?: number | null) {
    this.lat = lat || 0
    this.lng = lng || 0
    this.acc = acc || null
  }
}

@Component({
  components: {
    DateTimeRangeField: Utils.loadComponent('fields/DateTimeRangeField'),
    CategorySelector: Utils.loadComponent('CategorySelector'),
    VehicleListing: Utils.loadView('VehicleListing'),
    EmptyList: Utils.loadComponent('EmptyList'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'RTVehicleSearch',
})

export default class RTVehicleSearch extends Vue {
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @State((state) => state.booking.categories) public categoriesState!: VehicleCategory[]
  @State((state) => state.profile.userPosition) public userPosition!: Pos | null
  @State((state) => state.profile.userMesh) public userMesh!: UserMesh[]

  @Action('setTopbarTitle') public setTopbarTitle!: (t: string) => void

  public searchComplete: boolean = false
  public position: PositionClass = new PositionClass()
  // TODO: use this for something
  public positionUnavailable: boolean = true
  public features: any[] = []
  public bookingMode: keyof typeof BookingMode = 'RT'
  public categoriesEstimate: EstimateCategories[] = []
  public loading: boolean = false
  public estimateLoading: boolean = false

  protected innerValue: BookTimeSelectRequest = {start: '', end: ''}

  protected searchRequest: SearchRequest = {
    start: undefined,
    end: undefined,
    booking_mode: undefined,
    latitude: undefined,
    longitude: undefined,
    vehicle_type_id: undefined,
    vehicle_category_id: undefined,
  }
  protected searchResponse: VehicleSlot[] = []

  protected bookRequest: Partial<BookRequest> = {
    start: '',
    end: '',
    plate: '',
    memo: null,
  }

  protected valid: boolean = false

  protected get category() {
    return this.searchRequest.vehicle_category_id ?? null
  }

  protected set category(id: string[] | null) {
    this.$set(this.searchRequest, 'vehicle_category_id', [id])
  }

  protected selectedFeatures() {
    return this.features.filter((feature) => {
      return feature.selected === true
    })
  }

  @Watch('innerValue', {deep: true})
  protected isValid(val: BookTimeSelectRequest) {
    const start = moment(val.start, 'YYYY-MM-DD HH:mm:dd')
    const end = moment(val.end, 'YYYY-MM-DD HH:mm:dd')
    if (start.isValid() && end.isValid() && start.isBefore(end)) {
      this.searchRequest.start = val.start
      this.searchRequest.end = val.end
    } else {
      this.searchRequest.start = undefined
      this.searchRequest.end = undefined
    }
  }

  protected setPos() {
    const conf = this.appConfig
    if (this.userPosition) {
      this.$set(this, 'position', this.userPosition)
    } else if (conf) {
      this.$set(this, 'position', new PositionClass(conf.default_latitude, conf.default_longitude))
    }
  }

  protected locate() {
    Locate.locate(
      (pos) => {
        this.position = new PositionClass(pos.lat, pos.lng, pos.acc)
      },
      this.position,
      () => {
        this.positionUnavailable = true
        // TODO: investigate further use for error callback
      },
    )
  }

  protected created() {
    const params = this.$route.params
    const bm = (params.bookingMode || params.mode || 'RT').toUpperCase()// because it could also be CRT
    if (bm in BookingMode) {
      this.bookingMode = bm as keyof typeof BookingMode
    }
    if (this.$route.params.type) {
      const type = this.$route.params.type.toLowerCase()
      const mesh = this.userMesh.find((m) => m.vehicle_type.toLowerCase() === type)
      this.searchRequest.vehicle_type_id = mesh?.vehicle_type_id ? [mesh.vehicle_type_id] : []
    }
  }

  protected mounted() {
    this.setPos()
    this.locate()
  }

  protected calculateDistance(start: string, end: string) {
    if (this.appConfig.fields_configuration.estimateestimateConfig?.distance?.includes('set0')) {
      return 0
    } else {
      const startM = moment(start, 'YYYY-MM-DD HH:mm:ss')
      const endM = moment(end, 'YYYY-MM-DD HH:mm:ss')
      return Math.abs(Math.floor(startM.diff(endM, 'hours')) * 7)
    }
  }

  protected getCategoryEstimate() {
    const start = this.searchRequest.start
    const end = this.searchRequest.end
    if (start && end) {
      const distance = this.calculateDistance(start, end)
      this.estimateLoading = true
      sdk.booking.estimateAll({
        distance,
        start,
        end,
        booking_mode: this.bookingMode,
      }).then((r) => {
        this.categoriesEstimate = r.data
        this.estimateLoading = false
      }, () => {
        this.estimateLoading = false
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected submit() {
    this.searchRequest.booking_mode = [this.bookingMode]
    this.searchRequest.latitude = this.position.lat
    this.searchRequest.longitude = this.position.lng
    // this.searchRequest.vehicle_category_id = 'ZEdw40'
    // this.searchRequest.vehicle_type_id = null
    this.loading = true
    sdk.booking.search(this.searchRequest).then((r) => {
      this.searchResponse = r.data
      this.setTopbarTitle('vehicle.vehicle_listing')
      this.searchComplete = true
      this.loading = false
    })
    this.getCategoryEstimate()
  }

  protected selectVehicle(vehicleSlot: VehicleSlot) {
    this.bookRequest.plate = vehicleSlot.vehicle.plate
    this.bookRequest.start = this.searchRequest.start
    this.bookRequest.end = this.searchRequest.end
    this.$popup.open(VehicleBookingSummary, {
      props: {vehicleSlot, reservationRequest: this.bookRequest},
      title: this.$t('vehicle.booking_summary'),
    })
  }
}
